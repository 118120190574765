if (window.i18nResources == null) {
  window.i18nResources = {};
}

if (window.i18nResources["en"] == null) {
  window.i18nResources["en"] = {};
}

window.i18nResources["en"]["translation"] = {
  "edit_table_integration": "Edit Table Integration Settings",
  "edit_db_integration": "Edit Database Integration Settings",
  "new_form_integration": "New Form Integration",
  "new_procedure": "New Procedure",
  "at_least": "(At least {{count}} character)",
  "at_least_plural": "(At least {{count}} characters)",
  "maximum_of_char": "(Maximum of {{count}} character)",
  "maximum_of_char_plural": "(Maximum of {{count}} characters)",
  "character_range": "({{min}}–{{max}} characters)",
  "num_characters": "({{count}} character)",
  "num_characters_plural": "({{count}} characters)",
  "greater_than": "(Greater than {{count}})",
  "less_than": "(Less than {{count}})",
  "range": "({{min}}–{{max}})",
  "unsaved_changes": "Unsaved Changes",
  "new_integration": "New Integration",
  "are_you_sure": "Are you sure you want to continue without saving your changes?",
  "are_you_sure_proc": "Are you sure you want to continue without saving? Click Save Procedure in the Editor to save your changes.",
  "are_you_sure_post": "Are you sure you want to continue without saving? Click Save Page in the Editor to save your changes.",
  "no": "No",
  "new": "New",
  "done": "Done",
  "cancel": "Cancel",
  "export": "Export",
  "clone": "Clone",
  "delete": "Delete",
  "restore": "Restore",
  "update": "Update",
  "mass_update": "Update",
  "other": "Other",
  "accept": "Accept",
  "decline": "Decline",
  "continue_without_saving": "Continue Without Saving",
  "yes": "Yes",
  "ok": "OK",
  "more_options": "More…",
  "exit_modal": "Exit Modal",
  "toggle_sidebar": "Toggle Sidebar",
  "not_valid_permalink": "\"{{permalink}}\" is not a valid form permalink",
  "permission_denied": "Permission Denied",
  "permission_denied_desc": "You don't have permission to make connections to that form.",
  "comment_fail": "Failed to save comment",
  "close_window": "Close this window",
  "owner": "Owner",
  "is_owner": "{{username}} owns this organization.",
  "connection_error": "Connection Error",
  "connection_error_msg": "We couldn't connect to Sonadier. Are you connected to the Internet? Email us at {{email}} if this continues.",
  "request_error": "Request Error",
  "request_error_msg": "An error occurred while handling submitted data. Please correct any errors and try again. If this continues, email us at {{email}}.",
  "session_error": "Session Error",
  "session_error_msg": "Your session has expired. Please {{sign_in}} or {{refresh}} to continue.",
  "errors": {
    "status_codes": {
      "payment_required": {
        "title": "Missing Feature {{feature}}"
      },
      "request_entity_too_large": {
        "title": "Request Too Large",
        "description": "The uploaded file or data sent with this request was too large. Contact {{email}} to save this data manually."
      },
      "forbidden": {
        "title": "Unauthorized",
        "description": "You don't have permission to open that page. Try refreshing the page, logging in again, or contacting your organization's manager."
      }
    }
  },
  "upload_error": "Upload Error",
  "unsaved_file": "Unsaved File",
  "conflicting_changes": "This {{response}} has changed since you started working.",
  "conflict_instructions": "Use the checkboxes to override your own changes, or combine the changes manually. Save the response again when you're finished.",
  "name": "Name",
  "save": "Save",
  "file_will_be_deleted": "This file will be deleted upon submission",
  "uploaded_to_server": "Uploaded to the server",
  "integrate_with": "Integrate With {{name}}",
  "integration_requires_access": "This form requires access to your <b>{{name}}</b> account. Declining will return you to your dashboard.",
  "integration_requests_access": "This form is requesting access to your <b>{{name}}</b> account.",
  "settings": "Settings",
  "settings_for": "Settings for {{name}}",
  "publishing_data_to": "Publishing Data to {{name}}",
  "publishing": "Publishing",
  "are_you_sure_short": "Are you sure?",
  "edit_integration_settings": "Edit Integration Settings",
  "cancel_this_invitation": "Cancel this invitation",
  "no_user_with_username": "No user with that username exists.",
  "already_member": "That user is already a member of your facility.",
  "already_invited": "That person has already been invited to your facility.",
  "user_limit": "You've reached the maximum number of users available on your plan.",
  "default_invitation_msg": "Your invitation could not be sent.",
  "new_group": "New Group",
  "connect_with": "Connect with {{name}}",
  "failed_to_connect": "Failed to connect to {{name}}",
  "couldnt_connect_to_acc": "We couldn't connect to your account. Please try again.",
  "integrated_with": "Integrated with {{name}}",
  "add_x_account": "Add {{name}} Account",
  "have_no_files": "You don't have any uploaded files.",
  "open_in_new_tab": "Open in New Tab",
  "open": "Open",
  "get_a_link": "Get a Link",
  "rename": "Rename",
  "edit": "Edit",
  "move": "Move",
  "properties": "Properties",
  "permissions": "Permissions",
  "custom_ordering": "Order",
  "advanced_search": "Advanced Search",
  "search": "Search",
  "filter": "Filter",
  "workflows_editor": "Workflows",
  "column_options": {
    "new_field": "New Field",
    "new_formula": "New Formula",
    "advanced_search": "Filter",
    "ordering": "Ordering",
    "coloring": "Coloring",
    "rename": "Rename",
    "hide": "Hide Column",
    "new_resource": {
      "grouping": "Group",
      "aggregation": "Summarize",
      "calendar": "New Calendar",
      "kanban": "New Kanban"
    }
  },
  "reports": {
    "methods": {
      "is": "Is",
      "is_not": "Is Not",
      "before": "Before",
      "after": "After",
      "between": "Between",
      "not_between": "Not Between",
      "is_empty": "Is Empty",
      "is_not_empty": "Is Not Empty"
    },
    "save": "Save Report",
    "all": "All",
    "any": "Any",
    "group": "Group",
    "condition": "Condition",
    "rename": "Rename",
    "edit": "Edit Conditions"
  },
  "hide_column": "Hide Column",
  "rename_column": "Rename",
  "rename_this_file": "Rename this File",
  "responses_selected": "{{count}} response",
  "responses_selected_plural": "{{count}} responses",
  "files_selected": "{{count}} file",
  "files_selected_plural": "{{count}} files",
  "forms_selected": "{{count}} form",
  "forms_selected_plural": "{{count}} forms",
  "new_response": "New Response",
  "forms": {
    "edit": "Edit Form"
  },
  "form_folders": {
    "edit": "Edit Folder"
  },
  "calendars": {
    "edit": "Edit Calendar"
  },
  "kanbans": {
    "edit": "Edit Kanban"
  },
  "submissions": {
    "subscriptions": {
      "subscribe": {
        "name": "Subscribe",
        "description": "Send notifications for changes and comments on this response."
      },
      "unsubscribe": {
        "name": "Unsubscribe",
        "description": "Disable notifications for changes and comments to this response."
      }
    },
    "drafts": {
      "saved": "Draft Saved"
    }
  },
  "response_table": {
    "new_response": "New Response",
    "response": "Response",
    "responses": "Responses",
    "page_count": "{{start}}-{{finish}} of {{total}}",
    "no_responses": "No responses found!",
    "no_responses_create": "No responses found! Why not {{create_link}} or {{import_link}} some?",
    "no_responses_create_link_text": "create",
    "no_responses_import_link_text": "import",
    "options": {
      "settings": "Column Settings",
      "export": "Export & Import",
      "filter": "Filter Responses",
      "restore": "Restore Responses"
    },
    "mass_select": {
      "select_all": "(Select All)",
    },
    "aggregating": {
      "operator_names": {
        "default": {
          "sum": "Sum",
          "average": "Average",
          "median": "Median",
          "maximum": "Maximum",
          "minimum": "Minimum",
          "count": "Count"
        }
      }
    },
    "grouping": {
      "operator_names": {
        "date_time": {
          "millennia": "Millennia",
          "century": "Century",
          "decade": "Decade",
          "year": "Year",
          "quarter": "Quarter",
          "quarter_of_year": "Quarter of Year",
          "month": "Month",
          "month_of_year": "Month of Year",
          "week": "Week",
          "day": "Day",
          "day_of_year": "Day of Year",
          "day_of_month": "Day of Month",
          "day_of_week": "Day of Week",
          "hour": "Hour",
          "hour_of_day": "Hour of Day",
          "minute": "Minute",
          "minute_of_hour": "Minute of Hour",
          "second": "Second",
          "second_of_minute": "Second of Minute"
        },
        "address": {
          "address": "Street Address",
          "city": "City",
          "postcode": "Postal Code",
          "region": "State or Region",
          "country": "Country"
        },
        "text": {
          "value": "Value"
        },
        "barcode": {
          "value": "Value"
        },
        "text_area": {
          "value": "Value"
        },
        "formula": {
          "value": "Value"
        },
        "dropdown": {
          "value": "Value"
        }
      }
    },
    "querying": {
      "operator_names": {
        "default": {
          "==": "Is",
          "!=": "Is Not",
          "!?": "Is Empty",
          "?": "Is Not Empty",
          "<": "Is Before",
          "<=": "Is or Is Before",
          ">": "Is After",
          ">=": "Is or Is After",
          "%": "Contains",
          "!%": "Does Not Contain"
        },
        "rating": {
          "<": "Is Below",
          "<=": "Is or Is Below",
          ">": "Is Above",
          ">=": "Is or Is Above"
        },
        "check_box": {
          "?": "Is Checked",
          "!?": "Is Not Checked"
        },
        "number": {
          "<": "Is Below",
          "<=": "Is or Is Below",
          ">": "Is Above",
          ">=": "Is or Is Above"
        },
        "address": {
          "[address]==": "Address Is",
          "[address]!=": "Address Is Not",
          "[address]%": "Address Contains",
          "[address]!%": "Address Does Not Contain",
          "[address]?": "Address Is Not Empty",
          "[address]!?": "Address Is Empty",
          "[city]==": "City Is",
          "[city]!=": "City Is Not",
          "[city]%": "City Contains",
          "[city]!%": "City Does Not Contain",
          "[city]?": "City Is Not Empty",
          "[city]!?": "City Is Empty",
          "[postcode]==": "Postal Code Is",
          "[postcode]!=": "Postal Code Is Not",
          "[postcode]%": "Postal Code Contains",
          "[postcode]!%": "Postal Code Does Not Contain",
          "[postcode]?": "Postal Code Is Not Empty",
          "[postcode]!?": "Postal Code Is Empty",
          "[region]==": "Region Is",
          "[region]!=": "Region Is Not",
          "[region]%": "Region Contains",
          "[region]!%": "Region Does Not Contain",
          "[region]?": "Region Is Not Empty",
          "[region]!?": "Region Is Empty",
          "[country]==": "Country Is",
          "[country]!=": "Country Is Not",
          "[country]%": "Country Contains",
          "[country]!%": "Country Does Not Contain",
          "[country]?": "Country Is Not Empty",
          "[country]!?": "Country Is Empty"
        }
      }
    }
  },
  "file_table": {
    "no_files_available": "No files found!",
    "no_files_available_create_one": "No files found! Why not upload one?"
  },
  "form_editor": {
    "unsaved": {
      "save": "Save",
      "continue": "Continue",
      "cancel": "Cancel"
    },
    "field_types": {
      "connection": {
        "connected_form": {
          "missing": "Choose a Connected Form to change your connection's display."
        }
      }
    }
  },
  "form_query": {
    "titles": {
      "field": "Field",
      "operator": "Operator",
      "value": "Value"
    },
    "condition": {
      "titles": {
        "destroy": "Remove Condition"
      }
    },
    "group": {
      "titles": {
        "destroy": "Remove Group"
      }
    }
  },
  "field_types": {
    "text": "Short Text",
    "text_area": "Paragraph",
    "check_box": "Checkbox",
    "number": "Number",
    "boolean": "Yes or No",
    "date_time": "Date or Time",
    "user": "User",
    "rating": "Rating",
    "address": "Address",
    "phone": "Phone Number",
    "attachment": "Attachment",
    "signature": "Signature",
    "barcode": "Barcode",
    "email": "Email"
  },
  "approvals": {
    "no_pending": "{{form}} has no submissions waiting for approval.",
    "no_rejected": "{{form}} has no rejected submissions.",
    "no_approved": "{{form}} has no approved submissions."
  },
  "response_subscriptions": {
    "forms": {
      "subscribe": "Subscribe to {{form}}",
      "unsubscribe": "Unsubscribe from {{form}}",
      "title": "Get Email Notifications for New Responses to {{form}}"
    },
    "reports": {
      "subscribe": "Subscribe to {{report}}",
      "unsubscribe": "Unsubscribe from {{report}}",
      "title": "Get Email Notifications for New Responses to {{report}}"
    }
  },
  "workflows": {
    "conditions": {
      "if": {
        "operator_names": {
          "==": "Is",
          "!=": "Is Not",
          ">": "Is Greater Than",
          ">=": "Is Greater Than or Equals",
          "<": "Is Less Than",
          "<=": "Is Less Than or Equals"
        }
      }
    }
  },
  "fields": {
    "dropdown": {
      "multiple": "(Select one or more)"
    }
  },
  "manage": {
    "rename_organization": "Rename Organization",
    "change_subdomain": "Change Custom Subdomain"
  },
  "preview": "Preview",
  "files": {
    "replace": "Replace"
  },
  "information": "Information",
  "notifications": {
    "been_resolved": "This notification has been resolved",
    "open_linked": "Click this notification to open the linked item",
    "official": "Official Announcement",
    "have_unread": "You have unread notifications."
  },
  "history": "History"
}
